import { Device } from "./device";

export interface DeviceCol {
        field: keyof Device;
        header: string;
        type: string;
}

export class DeviceColumns {
    static cols: Array<DeviceCol> = [
            { field: 'id', header: 'Id', type: 'text' },
            { field: 'manufacturerSerialNumber', header: 'Serial Number', type: 'text' },
            { field: 'manufacturerPartNumber', header: 'Part Number', type: 'text' },
            { field: 'boardName', header: 'Board Name', type: 'text' },
            { field: 'imageVersion', header: 'Image Version', type: 'text' },
            { field: 'biosVersion', header: 'Bios Version', type: 'text' },
            { field: 'since', header: 'Provision time' , type: 'date'},
            { field: 'until', header: 'Valid Until', type: 'date' },
            { field: 'manufacturer', header: 'Manufacturer', type: 'text' },
            { field: 'macAddress1', header: 'Mac Address 1', type: 'text' },
            { field: 'macAddress2', header: 'Mac Address 2', type: 'text' },
            { field: 'loepfePartNumber', header: 'Loepfe P/N', type: 'text' },
            { field: 'loepfeSerialNumber', header: 'Assembler S/N', type: 'text' },
            { field: 'assemblerPartNumber', header: 'Assembler P/N', type: 'text' },
            { field: 'isBox', header: 'Box', type: 'text' },
            { field: 'testedBy', header: 'Safety checked by', type: 'text' },
            { field: 'assembledBy', header: 'Assembled by', type: 'text' },
        ];
}
