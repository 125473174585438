<div *ngIf="device">
    <p-card header="LZE-6">
        <table>
            <tbody>
            <tr *ngFor="let col of cols">
                <td>{{col.header}}:</td>
                <td>
                    <div class="container">
                        <ng-template *ngIf="col.type === 'date'; then dateField else normalField"></ng-template>
                        <ng-template #dateField>
                            <ng-template *ngIf="device[col.field] > dateNow; then outputValid else outputDate"></ng-template>
                            <ng-template #outputValid>Currently valid</ng-template>
                            <ng-template #outputDate>{{$any(device[col.field]) | date:'yyyy-MM-dd, HH:mm:ss'}}</ng-template>
                        </ng-template>
                        <ng-template #normalField>
                            {{device[col.field]}}
                        </ng-template>
                    </div>
                </td>

            </tr>
            </tbody>
        </table>

    </p-card>
</div>
