import { Component, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs/internal/operators/finalize';
import { map } from 'rxjs/operators';
import { Device } from 'src/app/models/device';
import { DeviceColumns } from 'src/app/models/device-columns';
import { DeviceService } from 'src/app/services/device.service';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html'
})
export class DevicesComponent implements OnInit {

  devices: Device[] = [];
  dateNow: Date = new Date();

  totalRecords: number = 10;

  cols: any[] = [];
  _selectedColumns: any[] = [];

  loading: boolean = false;

  constructor(private deviceService: DeviceService) { }

  ngOnInit(): void {
    this.loading = true;
    this.getDevices();
    this.cols = DeviceColumns.cols;

    this.readSelectedColumnsFromLocalStorage();
  }

  getDevices(): void {
    this.deviceService.getDevicees().pipe(finalize(() => this.finalize()))
      .subscribe(devices => {this.devices = devices});
  }

  private readSelectedColumnsFromLocalStorage() {
    var c = localStorage.getItem('selectedColumns');
    var columns = c ? JSON.parse(c) : []
    if (columns.length > 0) {
      columns.forEach((e: { [x: string]: any; }) => {
        var column = this.cols.find(value => value.field == e["field"])
        if (column)
          this._selectedColumns.push(column);
      });
    } else {
      this._selectedColumns = this.cols;
    }
  }

  finalize() {
    this.loading = false;
    console.log(this.devices)
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter(col => val.includes(col));
    localStorage.setItem('selectedColumns', JSON.stringify(this._selectedColumns));
  }

  clear(table: any) {
    table.clear();
  }

}
