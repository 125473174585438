import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { Device } from 'src/app/models/device';
import { environment } from 'src/environments/environment';
import { Response } from 'src/app/models/response';
import { MsalService } from "@azure/msal-angular";
import { AccountInfo } from "@azure/msal-common";

interface Account extends AccountInfo {
  idTokenClaims?: {
    roles?: string[]
  }
}

export const roles = {
  KontronUser: "KontronUser",
  PortwellUser: "PortwellUser",
  LoepfeUser: "LoepfeUser"
}

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  private DeviceesUrl = environment.auth.resources.todoListApi.resourceUri+ "/Devices";  // URL to web api

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private authService: MsalService) { }
  
  getEndpoint(): string {
    let account: Account = this.authService.instance.getAllAccounts()[0];
    
    if (account.idTokenClaims?.roles?.includes(roles.KontronUser))
      return '/knt';
    
    if (account.idTokenClaims?.roles?.includes(roles.PortwellUser))
      return '/pw';
    
    return '';
  }

  /** GET Devicees from the server */
  getDevicees(): Observable<Array<Device>> {
    return this.http.get<Response<Array<Device>>>(this.DeviceesUrl+this.getEndpoint())
      .pipe(
        tap(_ => this.log('fetched Devicees')),
        map(response => response.data.map((dev: Device) => new Device().deserialize(dev))),
        catchError(this.handleError<Device[]>('getDevicees', []))
      )
  }

  /** GET Devicees from the server */
  getDevice(id: string): Observable<Device> {
    return this.http.get<Device>(this.DeviceesUrl+this.getEndpoint()+"/"+id)
        .pipe(
            tap(_ => this.log(`Fetched device id: ${id}`)),
            map(device => new Device().deserialize(device)),
            catchError(this.handleError<Device>('getDevice'))
        )
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a DeviceService message with the MessageService */
  private log(message: string) {
    console.log(`DeviceService: ${message}`);
  }
}
