import { Deserializable } from "./deserializable";

export class Device implements Deserializable<Device> {
  constructor(obj?: Partial<Device>) {
    Object.assign(this, obj);
  }

  biosVersion: string;
  boardName: string;
  id: string;
  imageVersion: string;
  loepfePartNumber: string;
  loepfeSerialNumber: string;
  macAddress1: string;
  macAddress2: string;
  manufacturer: string;
  manufacturerPartNumber: string;
  manufacturerSerialNumber: string;
  since: Date;
  special: string;
  until: Date;
  isBox: boolean;
  testedBy: string;
  assembledBy: string;
  assemblerPartNumber: string;

  deserialize(input?: Partial<Device>): this {
    Object.assign(this, input);
    if (input?.until)
      this.until = new Date(input.until)
    if (input?.since)
    this.since = new Date(input.since)
    return this;
  }
}

