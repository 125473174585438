// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// @ts-nocheck
export const environment = {
  production: false,

  apiUrl: "https://productiondatacollector.dev.loepferd.com/api",
  auth: {
      credentials: {
        clientId: "260b018d-6668-49e7-a27a-9154641b6620",
        tenantId: "0a71be8b-58f2-4b2c-ab58-0c5776bfe1e6"
      },
      configuration: {
        redirectUri: "https://productiondatacollector.dev.loepferd.com",
        postLogoutRedirectUri: "https://productiondatacollector.dev.loepferd.com" 
      },
      resources: {
        todoListApi: {
          resourceUri: "https://productiondatacollector.dev.loepferd.com/api",
          resourceScopes: ["api://564a13bd-1ec7-4925-9fca-e81737d73a93/user_impersonation"]
        }
      }
    }
};

// For local development, change the urls from "https://productiondatacollector.dev.loepferd.com" to "localhost" and add the port info
// Ex:
// apiUrl = "https://localhost:5001/api";
// redirectUri = "http://localhost:4200";
// postLogoutRedirectUri = "http://localhost:4200";