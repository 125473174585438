<p-toolbar>
  <div class="p-toolbar-group-left">
    <p-button label="Home" [routerLink]="['/']"></p-button>
    <p-button label="Lze-6 devices"[routerLink]="['app-devices']"></p-button>
  </div>


  <div class="p-toolbar-group-right">
    <p-button styleClass="p-button-success" *ngIf="!loggedIn" (click)="login()">Login</p-button>
    <p-button styleClass="p-button-danger" *ngIf="loggedIn" (click)="logout()">Logout</p-button>
  </div>
</p-toolbar>

<div class="container">
  <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
  <router-outlet *ngIf="!isIframe"></router-outlet>
</div>
