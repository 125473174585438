import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Device } from 'src/app/models/device';
import {DeviceCol, DeviceColumns } from 'src/app/models/device-columns';
import { DeviceService } from 'src/app/services/device.service';


@Component({
  selector: 'app-device-detail',
  templateUrl: './device-detail.component.html'
})
export class DeviceDetailComponent implements OnInit {
  @Input() device?: Device;
  cols: DeviceCol[];
  dateNow: Date = new Date()
  constructor(private deviceService: DeviceService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.getDevice();
    this.cols = DeviceColumns.cols;
  }
  
  getDevice(): void {
    const id = String(this.route.snapshot.paramMap.get('id'));
    this.deviceService.getDevice(id).subscribe(device => {
      this.device = device
      console.log(this.device)
    });
  }
}
