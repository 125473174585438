<div class="card">
  <p-table #dt1 [columns]="selectedColumns" [value]="devices" dataKey="id" [scrollable]="true"
           [reorderableColumns]="true"
           stateStorage="local" stateKey="statedemo-local"
           [rows]="25" [showCurrentPageReport]="true" [rowsPerPageOptions]="[25,50]" [loading]="loading"
           [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                       selectedItemsLabel="{0} columns selected" [style]="{minWidth: '200px'}"
                       placeholder="Choose Columns"></p-multiSelect>
        <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt1.filterGlobal($any($event.target).value, 'contains')"
                       placeholder="Search keyword"/>
            </span>
        <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>
        <button type="button" pButton pRipple icon="pi pi-file-o" (click)="dt1.exportCSV()" class="p-mr-2"
                pTooltip="CSV" tooltipPosition="bottom"></button>
      </div>
    </ng-template>
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns" style="width:140px">
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" pSortableColumn="{{col.field}}" pReorderableColumn>
          {{col.header}}
          <p-sortIcon field="{{col.field}}"></p-sortIcon>
          <p-columnFilter type="{{col.type}}" field="{{col.field}}" display="menu"></p-columnFilter>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-device let-columns="columns">
      <tr>
        <td *ngFor="let col of columns">
          <div class="container">
            <ng-template *ngIf="col.type === 'date'; then dateField else normalField"></ng-template>
            <ng-template #dateField>
              <ng-template *ngIf="device[col.field] > dateNow; then outputValid else outputDate"></ng-template>
              <ng-template #outputValid>Currently valid</ng-template>
              <ng-template #outputDate>{{device[col.field] | date:'yyyy-MM-dd, HH:mm:ss'}}</ng-template>
            </ng-template>
            <ng-template #normalField>
              <ng-template *ngIf="col.field === 'id'; then outputLink else outputNormal"></ng-template>
              <ng-template #outputLink>
                <a routerLink="/device-detail/{{device[col.field]}}">
                  ...{{device[col.field] | slice:14}}
                </a>
              </ng-template>
              <ng-template #outputNormal>
                {{ (device[col.field].length>18 )? '..'+(device[col.field]| slice:-16):(device[col.field]) }}
              </ng-template>
            </ng-template>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="columns.length">
          No records found
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
